<template>
  <Header />

  <!-- hero -->
  <Hero />
  <!-- hero -->

  <!-- body -->
  <About />
  <!-- body -->
  <Impact />

  <Video />
  <!-- body -->
  <Gallery />
  <!-- body -->

  <!-- body -->
  <Footer />
  <!-- body -->

  <Head>
    <title>Supporting SaiGon - VietSwiss</title>
    <meta name="description" content="We are a small team with many wonderful helpers, living and working in Vietnam. All our volunteers work in their free time, so that 100% of our fundings can reach the ones in urgent need. We target the poorest within our community, who can not help themselves." />
    <meta name='keywords' content='donation, covid, vietnam, saigon, fundings, community, volunteers'>
    <meta name='subject' content='Supporting SaiGon - VietSwiss'>
    <meta name='author' content='vietswiss, info@vietswiss.com'>
    <meta name='owner' content='VietSwiss'>
    <meta name='medium' content='blog'>
    <meta itemprop='name' content='Supporting SaiGon - VietSwiss'>
    <meta name="title" property="og:title" content="Supporting SaiGon - VietSwiss">
    <meta property="og:type" content="Website">
    <meta property="og:url" content="https://supporting.vietswiss.com" />
    <meta property='og:site_name' content='Supporting SaiGon - VietSwiss'>
    <meta property='og:country-name' content='Vietnam'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.4/tiny-slider.css">
  </Head>

</template>

<script>
import { Head } from '@egoist/vue-head'

import { defineAsyncComponent } from "vue";
// import AOS from 'aos';
// import 'aos/dist/aos.css'
// import { usePrismicAPI } from '@/use/usePrismic';


export default {
  name: 'App',
  components: {
    Header: defineAsyncComponent(() => import('@/components/Header')),
    Hero: defineAsyncComponent(() => import('@/components/Hero')),
    About: defineAsyncComponent(() => import('@/components/About')),
    Impact: defineAsyncComponent(() => import('@/components/Impact')),
    Video: defineAsyncComponent(() => import("@/components/Video")),
    Gallery: defineAsyncComponent(() => import('@/components/Gallery')),
    Footer: defineAsyncComponent(() => import('@/components/Home')),
    Head,
  },
  data() {
    return {
      isScroll: false,
    };
  },
  methods: {
    resizeHeaderOnScroll() {
      const distanceY =
        window.pageYOffset || document.documentElement.scrollTop;
      const shrinkOn = 150;
      const bodyElm = document.getElementsByTagName("BODY")[0];
      if (distanceY > shrinkOn) {
        bodyElm.classList.add("scrolling");
        this.isScroll = true;
      } else {
        bodyElm.classList.remove("scrolling");
        this.isScroll = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.resizeHeaderOnScroll);
  },

}
</script>



<style lang="scss">
 @import url('./assets/styles/_main.scss');
</style>
